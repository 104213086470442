import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios"; // Import axios library
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';


// import "../assets/css/product.css"; // Import custom CSS file for styling

const DatatableProductscat = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openModal, setOpenModal] = useState(false); // State for modal visibility
  const [pic, setPic] = useState([]);
  const [filename, setFilename] = useState([]);
  const [allSelected, setAllSelected] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
     const sub_cat_id = localStorage.getItem("sub_cat_id");
      const api_url = localStorage.getItem('api_url');
      const res = await axios.get(api_url + '/admingetProducts1/'+sub_cat_id);
      const data = await res.data.products;
      setData(data);
      setFilteredData(data);
    };

    fetchData();
  }, []);

  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    setFilterText(value);
    filterData(value, sortField, sortDirection);
  };

  const handleDivClick1 = () => {
    navigate("/Associateproducts", { replace: true });
    // this.props.history.push('/viewProduct');
  };

  const handleSelectAll = (isChecked) => {
    setAllSelected(isChecked);
    if (isChecked) {
      setSelectedRows(filteredData);
    } else {
      setSelectedRows([]);
    }
  };

  const filterData = (filterValue, sortField, sortDirection) => {
    const filteredItems = data.filter((item) =>
      Object.keys(item).some(
        (key) =>
          key !== "id" &&
          item[key].toString().toLowerCase().includes(filterValue.toLowerCase())
      )
    );

    if (sortField && sortDirection) {
      filteredItems.sort((a, b) => {
        const fieldA = a[sortField].toString().toLowerCase();
        const fieldB = b[sortField].toString().toLowerCase();
        return sortDirection === "asc" ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
      });
    }

    setFilteredData(filteredItems);
  };

  const handleSort = (column, sortDirection) => {
    setSortField(column.selector);
    setSortDirection(sortDirection);
    filterData(filterText, column.selector, sortDirection);
  };

  const navigate = useNavigate();


  const columns = [
  
    {
      name: 'Item ID',
      selector: row => row.item_id,
      sortable: true,
    },
    {
      name: 'SKU',
      selector: row => row.item_sku,
      sortable: true,
    },
    {
      name: 'Barcode Value',
      selector: row => row.barcode_value,
      sortable: true,
    },
    {
      name: 'Title',
      selector: row => row.product_name,
      sortable: true,
    },
    {
      name: 'List Price',
      selector: row => '₹'+row.price+'.00',
      sortable: true,
    },
    {
      name: 'Offer Price',
      selector: row => '₹'+row.price+'.00',
      sortable: true,
    },
    {
      name: 'Inventory on Hand',
      selector: row => row.quantity,
      sortable: true,
    }
    
  ];

  return (
    <div>
       <div className="pro_buttons1">
            <button className="create_tem1" onClick={handleDivClick1} >ASSOCIATE PRODUCTS</button>
            </div>
         <br></br>
        <p className="ass_products"><b>Products</b></p>
    <div className="">
      
      <DataTable
  columns={columns}
  data={filteredData}
  pagination
  paginationPerPage={5}
  paginationRowsPerPageOptions={[5, 10, 15,50,100,500,1000,10000]}
  highlightOnHover
  // onSort={handleSort}
  sortServer={false}

  // selectableRows
  // selectableRowsHighlight
  // selectableRowsNoSelectAll
  // selectableRowsVisibleOnly
  customStyles={{
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
    rows: {
      style: {
        "&:nth-of-type(odd)": {
          backgroundColor: "#f3f3f3",
        },
      },
    },
  }}
/>
    </div>



    </div>
  );
};

export default DatatableProductscat;
