import React from "react";
import { withRouter } from  "../components/withRouter";
import axios from 'axios';
import DataTable from 'react-data-table-component';
import InnerHeader from "../components/InnerHeader";
import SidebarOne from "../components/sidebar1";

class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      admin_id : localStorage.getItem('admin_id'),
      data: [],
      search: '',
      prescription: 'no',
      suc_cls: '',
      errors: {},
    };
  }

  handleDivClick = (id) => {
    localStorage.setItem("user_id", id);
    this.props.navigate('/Viewusers'); // Fixed method call
  };

  
  handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this customer?")) {
      axios.get(`${this.state.api_url}/deleteCustomer/${id}`)
        .then(res => {
          if (res.data.status === "true") {
            this.callgetcustomersapi(); // Refresh the table after deletion
          } else {
            this.setState({ errors: { message: res.data.message } });
          }
        })
        .catch(err => {
          this.setState({ errors: { message: "Failed to delete customer." } });
        });
    }
  };

  componentDidMount() {
    this.callgetcustomersapi();
  }

  onChange_search = (e) => {
    this.setState({
      search: e.target.value
    }, () => this.callgetcustomersapi());
  }

  callgetcustomersapi = () => {
    let url = this.state.api_url + '/admingetCustomers';
   
      url = this.state.api_url + '/admingetCustomers/' + this.state.admin_id;
    axios.get(url)
      .then(res => {
        const data = res.data;
        this.setState({
          data: data.customers,
        });
      });
  }

  render() {
    const admin_id = localStorage.getItem('admin_id');

    const columns = [
      {
        name: 'Name',
        selector: row => row.firstname,
      },
      {
        name: 'Email',
        selector: row => row.email,
      },
      {
        name: 'Phone Number',
        selector: row => row.phone_number,
      },
      {
        name: 'Verified',
        selector: row => 'yes',
      },
      {
        name: admin_id === "664edaf4297f17146a9c5e7b" ? 'Action' : '',
        cell: (row) => {
          const admin_id = localStorage.getItem('admin_id');
      
          return (
            <div>
              {admin_id === "664edaf4297f17146a9c5e7b" ? (
                <button
                  className="tableBtn deleteBtn"
                  onClick={() => this.handleDelete(row._id)}
                >
                  Delete
                </button>
              ) : ""}
            </div>
          );
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
    ];

    return (
      <div>
        <div className="productsa">
          <SidebarOne />
          <div className="row" style={{width:"99%"}}>
            <InnerHeader />
            <hr />
          </div>
          <p className="user_m">User Management</p>
          <div className="frame-3 row">
            <div className="frame-5" style={{ left: "5px" }}>
              <input type="text" placeholder="Search..." className="searchtxt" value={this.state.search} onChange={this.onChange_search} />
            </div>
          </div>
          <div className="overlap-6">
            <div className={this.state.suc_cls}>{this.state.errors.message}</div>
            <div className="group-4">
              <DataTable
                columns={columns}
                data={this.state.data}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Customers);